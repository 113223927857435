import React, { createContext, useState } from "react";

const PlaceContext = createContext();

export const PlaceProvider = ({ children }) => {
  const [placeIdCommon, setPlaceIdCommon] = useState("ChIJ_0K0pW_AwjsR4bxItvVl850");
  const [placeLatCommon, setPlaceLatCommon] = useState("18.5164297");
  const [placeLngCommon, setPlaceLngCommon] = useState("73.85613289999999");
  const [displayNameCommon, setDisplayNameCommon] = useState("Srimant Dagadusheth Halawai Ganapati🕉️");



  return (
    <PlaceContext.Provider value={{ placeIdCommon, setPlaceIdCommon,placeLatCommon, setPlaceLatCommon,placeLngCommon, setPlaceLngCommon, displayNameCommon, setDisplayNameCommon }}>
      {children}
    </PlaceContext.Provider>
  );
};

export default PlaceContext;
