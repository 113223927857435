import GoogleMapComponent from "./GoogleMapComponent";
import React, { useEffect, useRef, useContext } from "react";
import PlaceContext from "../utils/PlaceContext";

const BlogTemplate = () => {
  const { placeLatCommon, placeLngCommon, displayNameCommon } =
    useContext(PlaceContext);

  return (
    <div className="flex items-center justify-center w-full p-2 md:p-4">
      <div className="rounded-xl shadow-2xl w-full md:w-10/12 lg:w-8/12 bg-gray-150">
        <div>
          <GoogleMapComponent
            latitude={placeLatCommon}
            longitude={placeLngCommon}
            displayName={displayNameCommon}
          />
        </div>
      </div>
    </div>
  );
};

export default BlogTemplate;
