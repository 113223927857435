import logo from "../logo.png";


const Header = () => {
  return (
    <div className="flex items-center justify-center rounded-b-md shadow-2xl w-8/12 font-bold p-3  mx-auto bg-gray-150">
      <img className="h-14" src={logo}/>
    </div>
  );
};

export default Header;
