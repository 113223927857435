import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="bg-white mt-10 border-t border-gray-300 flex flex-col md:flex-row flex-wrap items-center justify-between p-6 w-full md:w-10/12 lg:w-8/12 mx-auto rounded-t-xl shadow-2xl">
      
      <div className="mb-4 md:mb-0 w-full md:w-auto text-center md:text-left">
        <ul className=" flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-6">
          <li>
            <Link to="/" className="hover:text-blue-500 text-xl">
              Home
            </Link>
          </li>
          <li>
            <Link to="/about" className="hover:text-blue-500 text-xl">
              About Us
            </Link>
          </li>
          <li>
            <Link to="/contact" className="hover:text-blue-500 text-xl">
              Contact Us
            </Link>
          </li>
          <li>
            <Link to="/policies" className="hover:text-blue-500 text-xl">
              Policies
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Footer;
