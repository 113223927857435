
const Error = () => {
    return (
        <div style={styles.body}>
          <div style={styles.container}>
            <h1 style={styles.h1}>404</h1>
            <h2 style={styles.h2}>Oops! You're lost in space.</h2>
            <div style={styles.animation}>🚀</div>
            <p style={styles.p}>
              The page you're looking for isn't here. You might want to head back to safer ground.
            </p>
            <a href="/" style={styles.button}>Take me home</a>
          </div>
        </div>
      );
    };
    
    const styles = {
      body: {
        fontFamily: 'Arial, sans-serif',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: '#f4f4f4',
        margin: 0,
      },
      container: {
        textAlign: 'center',
        maxWidth: '600px',
        backgroundColor: '#fff',
        padding: '40px',
        boxShadow: '0px 4px 10px rgba(0,0,0,0.1)',
        borderRadius: '10px',
      },
      h1: {
        fontSize: '120px',
        margin: 0,
        color: '#FF6347',
      },
      h2: {
        fontSize: '24px',
        marginBottom: '10px',
      },
      p: {
        fontSize: '18px',
        color: '#666',
      },
      button: {
        display: 'inline-block',
        marginTop: '20px',
        padding: '10px 20px',
        backgroundColor: '#FF6347',
        color: 'white',
        borderRadius: '5px',
        textDecoration: 'none',
        transition: 'background-color 0.3s ease',
      },
      buttonHover: {
        backgroundColor: '#ff2f00',
      },
      animation: {
        fontSize: '48px',
        margin: '20px',
        animation: 'float 4s ease-in-out infinite',
      },
    };
export default Error;