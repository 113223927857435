const PlaceCheckBoxes = () => {
    return(<div className="">
        <div className="checkbox-container shadow-lg flex flex-auto space-x-9 text-lg p-4">
        <label>
          <input type="checkbox" value="tourist_attraction" />
          Tourist_attraction
        </label>
        <label>
          <input type="checkbox" value="park" />
          Park
        </label>
        <label>
          <input type="checkbox" value="restaurant" />
          Restaurant
        </label>
        <label>
          <input type="checkbox" value="park" />
          Park
        </label>
      </div>
      </div>
    )
}

export default PlaceCheckBoxes;