import React from "react";

const AboutUs = () => {
  return (
    <div className="flex items-center justify-center w-full p-2 md:p-4">
      <div className="rounded-xl shadow-2xl w-full md:w-10/12 lg:w-8/12 bg-gray-150">
        <div className="flex flex-col md:flex-row">
          <div className="m-10">
            <h1 className="text-3xl font-bold text-gray-800 mb-4">About Us</h1>
            <p className="text-gray-600 mb-4 text-2xl">
              Welcome to our blog dedicated to helping you discover the best
              places to visit! We leverage the power of Google Maps API to bring
              you detailed information about all the nearby attractions worth
              exploring. Whether you're in a bustling city or a remote location,
              our platform helps you find exciting places tailored to your
              interests.
            </p>
            <p className="text-gray-600 mb-4 text-2xl">
              Our mission is to inspire wanderlust and provide travelers with
              the tools they need to explore new destinations confidently. Our
              user-friendly interface allows you to search for places near you,
              click on engaging place cards, and view interactive maps to guide
              your adventures.
            </p>
            <p className="text-gray-600 mb-4 text-2xl">
              From hidden gems to popular attractions, we cover a wide range of
              experiences. Join us and let us help you
              create unforgettable memories!
            </p>
          </div>
        </div>

        <div className="p-6">
          <h2 className="text-3xl font-semibold text-gray-800 mb-4">
            Our Features
          </h2>
          <ul className="list-disc list-inside text-gray-600 mb-4  text-xl">
            <li>🌍 Comprehensive listings of nearby places.</li>
            <li>🔍 Powerful search functionality for remote locations.</li>
            <li>🗺️ Interactive maps to navigate effortlessly.</li>
            <li>📷 Stunning images of each location.</li>
            <li>✨ Regularly updated blog posts with tips and guides.</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
