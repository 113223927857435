import React from "react";

const Policies = () => {
  return (
    <div className="flex items-center justify-center w-full p-2 md:p-4">
      <div className="rounded-xl shadow-2xl w-full md:w-10/12 lg:w-8/12 bg-gray-150">
        <div className="m-10">
          <h1 className="text-3xl font-bold text-gray-800 mb-4">Our Policies</h1>

          <section className="mb-6">
            <h2 className="text-2xl font-semibold text-gray-800 mb-2">
              Privacy Policy
            </h2>
            <p className="text-gray-600 mb-4 text-xl">
              Welcome to our blog dedicated to helping you discover the best places to visit! We take your privacy seriously, and as of now, we do not collect, store, or use any personal information from our users through our platform.
            </p>
            <p className="text-gray-600 mb-4 text-xl">
              <strong>No Data Collection:</strong> Our blog does not track, collect, or store any personal data. We do not require users to create accounts, provide personal details, or share any form of identifying information.
            </p>
            <p className="text-gray-600 mb-4 text-xl">
              <strong>Google Maps API:</strong> Our blog integrates Google Maps API to provide detailed information about nearby places and attractions. While we do not store or manage any of your data, the Google Maps API may collect and process data, including location data, according to Google’s privacy practices. Please review <a href="https://policies.google.com/privacy" className="text-blue-500 hover:underline">Google’s Privacy Policy</a> for more information.
            </p>

          </section>

          <section className="mb-6">
            <h2 className="text-2xl font-semibold text-gray-800 mb-2">
              Terms & Conditions
            </h2>
            <p className="text-gray-600 mb-4 text-xl">
              By accessing and using our blog, you agree to the following terms and conditions:
            </p>
            <p className="text-gray-600 mb-4 text-xl">
              <strong>Use of Service:</strong> Our blog provides information about nearby attractions using the Google Maps API. While we strive to offer accurate and up-to-date information, we do not guarantee the completeness or accuracy of the information provided.
            </p>
            <p className="text-gray-600 mb-4 text-xl">
              <strong>User Responsibility:</strong> Users are responsible for how they interact with the information provided on our platform. We are not liable for any decisions or actions taken based on the content shared on the blog.
            </p>
            <p className="text-gray-600 mb-4 text-xl">
              <strong>Modifications:</strong> We reserve the right to change or update these terms at any time without prior notice. Continued use of our blog means you agree to the latest terms and conditions.
            </p>
          </section>

          <section className="mb-6">
            <h2 className="text-2xl font-semibold text-gray-800 mb-2">
              Disclaimer
            </h2>
            <p className="text-gray-600 mb-4 text-xl">
              The information provided on this blog is for general informational purposes only. While we aim to provide helpful and accurate details about places to visit, we cannot guarantee the completeness or reliability of the content.
            </p>
            <p className="text-gray-600 mb-4 text-xl">
              <strong>Google Maps API:</strong> Our blog uses Google Maps to display nearby attractions. We are not responsible for any inaccuracies, omissions, or third-party content presented via the Google Maps API.
            </p>
            <p className="text-gray-600 mb-4 text-xl">
              <strong>Travel Safety:</strong> You are responsible for your own safety when visiting any recommended locations. We are not liable for any incidents, accidents, or damages that occur during your travels.
            </p>
            <p className="text-gray-600 mb-4 text-xl">
              <strong>No Guarantees:</strong> While we offer recommendations, we cannot guarantee specific outcomes, experiences, or satisfaction at any of the locations mentioned on our blog.
            </p>
          </section>

          <section className="mb-6">
            <h2 className="text-2xl font-semibold text-gray-800 mb-2">
              Cookie Policy
            </h2>
            <p className="text-gray-600 mb-4 text-xl">
            We may use cookies or similar tracking technologies in the future to enhance your experience on our blog. These cookies could help us analyze website traffic, understand user interactions, and remember preferences, allowing us to improve our services and provide a more personalized experience. By continuing to use the blog, you agree to our potential use of cookies. You can control or disable cookies through your browser settings, but please note that some features of the blog may not function properly without them.
            </p>
          </section>
        </div>
      </div>
    </div>
  );
};

export default Policies;
