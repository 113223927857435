import Header from "./Header";
import MapAutocomplete from "./MapAutocomplete";
import BlogTemplate from "./BlogTemplate";
import Footer from "./Footer";
import UriParamsPlace from "./UriParamsPlace";

const Body = () => {
  return (
    <div>
      <MapAutocomplete />
      <UriParamsPlace/>
      <BlogTemplate />
    </div>
  );
};

export default Body;
