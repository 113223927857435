import React, { useEffect, useState, useContext  } from "react";
import PlaceContext from "../utils/PlaceContext";


const MapAutocomplete = () => {
  const [selectedPlace, setSelectedPlace] = useState(null);
  const {
    setPlaceIdCommon,
    setPlaceLatCommon,
    setPlaceLngCommon,
    setDisplayNameCommon
  } = useContext(PlaceContext);



  useEffect(() => {
    const initMap = async () => {
      await google.maps.importLibrary("places");
      const { Map, InfoWindow } = await google.maps.importLibrary("maps");


      const placeAutocomplete =
        new google.maps.places.PlaceAutocompleteElement();
      document
        .getElementById("autocomplete-container")
        .appendChild(placeAutocomplete);

      placeAutocomplete.addEventListener(
        "gmp-placeselect",
        async ({ place }) => {
          await place.fetchFields({
            fields: ["displayName", "formattedAddress", "location"],
          });

          setSelectedPlace(place.toJSON());
          setPlaceIdCommon((place.toJSON())?.id);
          setPlaceLatCommon((place.toJSON())?.location?.lat);
          setPlaceLngCommon((place.toJSON())?.location?.lng);
          setDisplayNameCommon((place.toJSON())?.displayName);
          console.log((place.toJSON())?.id)

        }
      );
    };

    initMap();

    return () => {
      const container = document.getElementById("autocomplete-container");
      if (container) {
        container.innerHTML = "";
      }
    };
  }, []);

  return (
    <div className=" flex items-center justify-center">
      <div
        className="w-8/12 flex items-center justify-center py-4 px-11"
        id="autocomplete-container"
      ></div>
    </div>
  );
};


export default MapAutocomplete;

// https://developers.google.com/maps/documentation/javascript/place-details



